import React, { useState, useEffect, useContext } from 'react'
import { format, parseISO } from 'date-fns'
import Header from '../../components/Header'
import Loading from '../../components/Loading'
import Api from '../../services/api'
import { Container } from './styles'
import Add from '../../assets/add.svg'
import Edit from '../../assets/edit.svg'
import Remove from '../../assets/remove.svg'
import calendario from '../../assets/calendario.png'
import { UserContext } from '../../context/userContext'
import { ptBR } from 'date-fns/locale'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export interface IUser {
  id: number
  username: string
  pass: string
  permission: number
}

interface IUser2 {
  id?: number
  username?: string
  pass?: string
  permission?: number
}

const initialUser: IUser = {
  id: 0,
  username: '',
  pass: '',
  permission: 0,
}

const secondUser: IUser2 = {}

export default function Users() {
  const [isLoading, setIsLoading] = useState(true)
  const [currentDate, setCurrentDate] = useState('')
  const [users, setUsers] = useState<IUser[]>([])
  const [oneUser, setOneUser] = useState<IUser>(initialUser)
  const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(null)
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)
  const [username, setUsername] = useState('')
  const [pass, setPass] = useState('')
  const [permission, setPermission] = useState(0)
  const [usernameEdit, setUsernameEdit] = useState('')
  const [passEdit, setPassEdit] = useState('')
  const [permissionEdit, setPermissionEdit] = useState(0)

  const userContext = useContext(UserContext)

  // Verifica se o contexto de usuário está definido antes de acessar suas propriedades
  if (!userContext) {
    throw new Error('UserContext is undefined')
  }

  const { user } = userContext

  const config = {
    headers: {
      Authorization: user.token,
    },
  }

  const formatDate = (date: Date | string) => {
    const parsedDate = typeof date === 'string' ? parseISO(date) : date
    return format(parsedDate, 'dd/MM/yyyy', { locale: ptBR })
  }

  const today = new Date()

  const getUsers = async () => {
    try {
      const { data } = await Api.get('/users', config)

      const sortedData = data.sort((a: IUser, b: IUser) => {
        return a.username.localeCompare(b.username)
      })

      setUsers(sortedData)
      setIsLoading(false)
    } catch (error) {
      // Trate o erro conforme necessário, como exibindo uma mensagem de erro para o usuário.
    }
  }

  const getOneUser = async (userId: number | null) => {
    const user = `/users/${userId}`

    try {
      const { data } = await Api.get(user, config)
      setOneUser(data)
      setIsLoading(false)
    } catch (error) {
      // Trate o erro conforme necessário, como exibindo uma mensagem de erro para o usuário.
    }
  }

  useEffect(() => {
    getUsers()
    setCurrentDate(formatDate(today))
  }, [user])

  const openAddModal = () => {
    if (user.username !== 'admin') {
      toast.info('Você precisa ser um administrador')
    } else {
      setIsAddModalOpen(true)
    }
  }

  const closeAddModal = () => {
    setIsAddModalOpen(false)
  }

  const openEditModal = () => {
    if (selectedItemIndex === null) {
      toast.warning('Selecione um usuario')
    } else {
      if (user.username !== 'admin') {
        toast.info('Você precisa ser um administrador')
      } else {
        setIsEditModalOpen(true)
      }
    }
    getOneUser(selectedItemIndex)
  }

  const closeEditModal = () => {
    setIsEditModalOpen(false)
  }

  const openRemoveModal = () => {
    if (selectedItemIndex === null) {
      toast.warning('Selecione um usuario')
    } else {
      if (user.username !== 'admin') {
        toast.info('Você precisa ser um administrador')
      } else {
        setIsRemoveModalOpen(true)
      }
    }
    getOneUser(selectedItemIndex)
  }

  const closeRemoveModal = () => {
    setIsRemoveModalOpen(false)
  }

  const cleanField = () => {
    setUsername('')
    setPass('')
    setPermission(0)
  }

  const addUser = async () => {
    if (permission !== 1 && permission !== 2 && permission !== 3 && permission !== 0) {
      setIsAddModalOpen(false)
      return toast.error('As permissões devem ser de 1 a 3')
    }
    if (username !== '' && pass !== '' && permission !== null) {
      // Criar o objeto newProduct apenas se a validação passar

      const newUser = { username, pass, permission }

      setIsLoading(true)

      try {
        await Api.post('/users', newUser, config)
        setIsAddModalOpen(false)
        setIsLoading(false)
        toast.success('Usuário adicionado com sucesso!')
        setSelectedItemIndex(null)
      } catch (error) {
        setIsAddModalOpen(false)
        setIsLoading(false)
        toast.error('Erro - Usuário não foi adicionado!')
      }
      cleanField()
      getUsers()
    } else {
      // Tratar o caso em que pelo menos um dos campos não atende às condições
      toast.error('Por favor, preencha todos os campos corretamente.')
    }
  }

  const updateProduct = async (productId: number | null) => {
    const updatedProduct: IUser2 = secondUser

    if (usernameEdit !== '' && usernameEdit !== '0') {
      updatedProduct.username = usernameEdit
    }

    if (passEdit !== '' && passEdit !== '0') {
      updatedProduct.pass = passEdit
    }

    if (permissionEdit !== 0) {
      updatedProduct.permission = permissionEdit
    }

    if (permissionEdit !== 1 && permissionEdit !== 2 && permissionEdit !== 3) {
      setIsEditModalOpen(false)
      return toast.error('As permissões devem ser 1, 2 ou 3')
    }

    // setIsLoading(true)
    try {
      // A rota de update, considerando que você use um ID para identificar o produto
      const updateUrl = `/users/${productId}`

      // Realiza a requisição de update
      await Api.put(updateUrl, updatedProduct, config)
      toast.success('Usuário atualizado com sucesso!')
      setIsEditModalOpen(false)
      setSelectedItemIndex(null)
      cleanField()
    } catch (error) {
      toast.error('Erro - Usuário não foi atualizado!')
      setIsEditModalOpen(false)
    }
    getUsers()
  }

  const removeUser = async (productId: number | null) => {
    setIsLoading(true)
    try {
      // A rota de delete, considerando que você use um ID para identificar o produto
      const deleteUrl = `/users/${productId}`

      // Realiza a requisição de delete
      await Api.delete(deleteUrl, config)

      toast.success('Usuário removido com sucesso!')
      setIsRemoveModalOpen(false)
      setSelectedItemIndex(null)
    } catch (error) {
      toast.error('Erro - Usuário não foi removido!')
      setIsRemoveModalOpen(false)
    }
    getUsers()
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [press, setPress] = useState(false)
  const [start, setStart] = useState(0)

  const handleMouseDown = () => {
    setPress(true)
    setStart(Date.now())
  }

  const handleMouseUp = () => {
    setPress(false)
    if (Date.now() - start > 500) {
      openEditModal()
    }
  }

  return (
    <>
      <Header />
      {/* <Footer /> */}
      <Container>
        <section className='estoque-section'>
          <h1>Usuários</h1>
          <div className='date'>
            <h1>{currentDate}</h1>
            <img src={calendario} alt='calendario' />
          </div>
        </section>

        <section className='tableControl'>
          <button type='button' className='add-btn' onClick={openAddModal}>
            <img src={Add} alt='adicionar produto' />
          </button>
          <button type='button' className='edit-btn' onClick={openEditModal}>
            <img src={Edit} alt='Editar produto' />
          </button>
          <button type='button' className='remove-btn' onClick={openRemoveModal}>
            <img src={Remove} alt='Remover produto' />
          </button>
        </section>
        <article className='all-users'>
          <section className='list-prop'>
            <div className='username'>
              <h2>Usuário</h2>
            </div>
            <div className='pass'>
              <h2>Senha</h2>
            </div>
            <div className='permission'>
              <h2>Permissão</h2>
            </div>
          </section>

          {isLoading ? (
            <Loading />
          ) : (
            <>
              {Array.isArray(users) &&
                users.map((element) => (
                  <section
                    key={element.id}
                    className={`list ${selectedItemIndex === element.id ? 'selected' : ''}`}
                    onClick={() => {
                      getOneUser(element.id)
                      setSelectedItemIndex((prevIndex) =>
                        prevIndex === element.id ? null : element.id,
                      )
                    }}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onTouchStart={handleMouseDown}
                    onTouchEnd={handleMouseUp}
                  >
                    <div className='username'>
                      <h2>{element.username}</h2>
                    </div>
                    <div className='pass'>
                      <h2>
                        {element.pass.length > 10
                          ? `${element.pass.substring(0, 5)}...`
                          : element.pass}
                      </h2>
                    </div>
                    <div className='permission'>
                      <h2>{element.permission}</h2>
                    </div>
                  </section>
                ))}
            </>
          )}
        </article>
        <div className='legendas'>
          <h2>Permissões</h2>
          <h3>Nivel 1 : Todas as permissões</h3>
          <h3>Nivel 2 : Restringe Estoque, Usuários e funções de exclusão.</h3>
          {/* <h3>Nivel 3 : Somente visualização</h3> */}
        </div>
        {isAddModalOpen && (
          <div className='modal'>
            <div className='add-edit'>
              <h1>Adicionar usuário</h1>

              <form action='/processar-formulario' method='post'>
                <div>
                  <h2>Nome</h2>
                  <input
                    type='text'
                    placeholder='Escolha um nome'
                    onChange={(e) => setUsername(e.target.value)}
                    minLength={3}
                    required
                  />
                </div>
                <div>
                  <h2>Senha</h2>
                  <input
                    type='text'
                    placeholder='Escolha sua senha'
                    onChange={(e) => setPass(e.target.value)}
                    minLength={3}
                    required
                  />
                </div>
                <div>
                  <h2>Permissão</h2>
                  <input
                    type='number'
                    placeholder='Somente 1,2 ou 3'
                    onChange={(e) => setPermission(Number(e.target.value))}
                    maxLength={1}
                    required
                  />
                </div>
              </form>
              <section className='buttons'>
                <button className='add-button' onClick={addUser}>
                  <img src={Add} alt='Adicionar' />
                  SIM
                </button>
                <button onClick={closeAddModal}>NÃO</button>
              </section>
            </div>
          </div>
        )}
        {isEditModalOpen && (
          <div className='modal'>
            <div className='add-edit'>
              <h1>Edite o usuário </h1>
              <form action='/processar-formulario' method='post'>
                <div>
                  <h2>Nome</h2>
                  <input
                    type='text'
                    placeholder='Escolha um nome'
                    onChange={(e) => setUsernameEdit(e.target.value)}
                    defaultValue={oneUser.username}
                    minLength={3}
                    required
                  />
                </div>
                <div>
                  <h2>Senha</h2>
                  <input
                    type='text'
                    placeholder='Escolha sua senha'
                    onChange={(e) => setPassEdit(e.target.value)}
                    defaultValue={oneUser.pass}
                    minLength={3}
                    required
                  />
                </div>
                <div>
                  <h2>Permissão</h2>
                  <input
                    type='number'
                    placeholder='Escolha o nível de permissão'
                    onChange={(e) => setPermissionEdit(Number(e.target.value))}
                    defaultValue={oneUser.permission}
                    maxLength={1}
                    required
                  />
                </div>
              </form>
              <section className='buttons'>
                <button className='edit-button' onClick={() => updateProduct(selectedItemIndex)}>
                  <img src={Edit} alt='EDITAR' />
                  SIM
                </button>
                <button onClick={closeEditModal}>NÃO</button>
              </section>
            </div>
          </div>
        )}

        {isRemoveModalOpen && (
          <div className='modal'>
            <div className='remove-modal'>
              <section className='info'>
                <h2>Remover usuário? </h2>
                <div>
                  <h2>{oneUser.username}</h2>
                </div>
              </section>
              <section className='buttons'>
                <button className='remove-button' onClick={() => removeUser(selectedItemIndex)}>
                  <img src={Remove} alt='RemoveAR' />
                  SIM
                </button>
                <button onClick={closeRemoveModal}>NÃO</button>
              </section>
            </div>
          </div>
        )}
      </Container>
      <ToastContainer />
    </>
  )
}
