import React, { useState, useEffect, useContext, MouseEvent } from 'react'
import { format, parseISO } from 'date-fns'
import Header from '../../components/Header'
// import Footer from '../../components/Footer'
import Loading from '../../components/Loading'
import Api from '../../services/api'
import { Container } from './styles'
import Add from '../../assets/add.svg'
import Edit from '../../assets/edit.svg'
import Remove from '../../assets/remove.svg'
import calendario from '../../assets/calendario.png'
import { UserContext } from '../../context/userContext'
import { ptBR } from 'date-fns/locale'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export interface Salgado {
  id: number
  name: string
  sabor: string
  qtd: number
  limite: number

  [key: string]: string | number | Date
}

interface Salgado2 {
  name?: string
  sabor?: string
  qtd?: number
  limite?: number
}

const initialProduct: Salgado = {
  id: 0,
  name: '',
  sabor: '',
  qtd: 0,
  limite: 0,
}

const secondProduct: Salgado2 = {}

export default function Estoque() {
  const [isLoading, setIsLoading] = useState(true)
  const [currentDate, setCurrentDate] = useState('')
  const [estoque, setEstoque] = useState<Salgado[]>([])
  const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(null)
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)
  const [name, setName] = useState('')
  const [sabor, setSabor] = useState('')
  const [qtd, setQtd] = useState(0)
  const [limite, setLimite] = useState(0)
  const [editName, setEditName] = useState('')
  const [editSabor, setEditSabor] = useState('')
  const [editQtd, setEditQtd] = useState(0)
  const [editLimite, setEditLimite] = useState(0)
  const [product, setProduct] = useState<Salgado>(initialProduct)
  // filtros
  const [ascendingOrder, setAscendingOrder] = useState<boolean>(false)

  // disable 3 buttons
  const [addDisable, setAddDisable] = useState<boolean>(true)
  const [editDisable, setEditDisable] = useState<boolean>(true)
  const [remDisable, setRemDisable] = useState<boolean>(true)

  const userContext = useContext(UserContext)

  // Verifica se o contexto de usuário está definido antes de acessar suas propriedades
  if (!userContext) {
    throw new Error('UserContext is undefined')
  }

  const { user } = userContext

  const config = {
    headers: {
      Authorization: user.token,
    },
  }

  const formatDate = (date: Date | string) => {
    const parsedDate = typeof date === 'string' ? parseISO(date) : date
    return format(parsedDate, 'dd/MM/yyyy', { locale: ptBR })
  }

  const today = new Date()

  const getProducts = async () => {
    try {
      const { data } = await Api.get('/products', config)

      // Ordena os dados primeiro pelo nome e depois pelo sabor
      const sortedData = data.sort((a: Salgado, b: Salgado) => {
        const nameComparison = a.name.localeCompare(b.name)
        // Se os nomes são iguais, compare pelo campo 'sabor'
        return nameComparison !== 0 ? nameComparison : a.sabor.localeCompare(b.sabor)
      })

      setEstoque(sortedData)
      setIsLoading(false)
    } catch (error) {
      // Trate o erro conforme necessário, como exibindo uma mensagem de erro para o usuário.
    }
  }

  const getOneProduct = async (productId: number | null) => {
    const product = `/products/${productId}`

    try {
      const { data } = await Api.get(product, config)
      setProduct(data)
      setIsLoading(false)
      console.log(data.qtd)
    } catch (error) {
      // Trate o erro conforme necessário, como exibindo uma mensagem de erro para o usuário.
    }
  }

  useEffect(() => {
    getProducts()
    setCurrentDate(formatDate(today))
  }, [user])

  const openAddModal = () => {
    if (user.permission !== 1) {
      toast.info('Você precisa ser um administrador')
    } else {
      setAddDisable(false)
      setIsAddModalOpen(true)
    }
  }

  const closeAddModal = () => {
    setIsAddModalOpen(false)
  }

  const openEditModal = () => {
    if (selectedItemIndex === null) {
      toast.warning('Selecione um produto')
    } else {
      if (user.permission !== 1) {
        toast.info('Você precisa ser um administrador')
      } else {
        setEditDisable(false)
        setIsEditModalOpen(true)
      }
    }
    getOneProduct(selectedItemIndex)
  }

  const closeEditModal = () => {
    setIsEditModalOpen(false)
  }

  const openRemoveModal = () => {
    if (selectedItemIndex === null) {
      toast.warning('Selecione um produto')
    } else {
      if (user.permission !== 1) {
        toast.info('Você precisa ser um administrador')
      } else {
        setRemDisable(false)
        setIsRemoveModalOpen(true)
      }
    }
    getOneProduct(selectedItemIndex)
  }

  const closeRemoveModal = () => {
    setIsRemoveModalOpen(false)
  }

  const cleanFieldProducts = () => {
    setName('')
    setSabor('')
    setQtd(0)
    setLimite(0)
  }

  const addProduct = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    // Verificar se o botão está desativado
    if (!addDisable) {
      // Verificar se todos os campos atendem às condições
      if (
        name !== '' &&
        sabor !== '' &&
        qtd !== null &&
        qtd !== 0 &&
        limite !== null &&
        limite !== 0
      ) {
        // Criar o objeto newProduct apenas se a validação passar
        const newProduct = { name, sabor, qtd, limite }

        setIsLoading(true)

        try {
          setAddDisable(true)
          await Api.post('/products', newProduct, config)
          setIsAddModalOpen(false)
          setIsLoading(false)
          toast.success('Produto adicionado com sucesso!')
          setSelectedItemIndex(null)
        } catch (error) {
          setIsAddModalOpen(false)
          setIsLoading(false)
          toast.error('Erro - Produto não foi adicionado!')
        } finally {
          cleanFieldProducts()
          getProducts()
        }
      } else {
        // Tratar o caso em que pelo menos um dos campos não atende às condições
        toast.error('Por favor, preencha todos os campos corretamente.')
      }
    }
  }

  const updateProduct = async (productId: number | null) => {
    const updatedProduct: Salgado2 = secondProduct

    if (editName !== '' && editName !== '0') {
      updatedProduct.name = editName
    }

    if (editSabor !== '' && editSabor !== '0') {
      updatedProduct.sabor = editSabor
    }

    if (editQtd !== 0) {
      updatedProduct.qtd = editQtd
    }

    if (editLimite !== 0) {
      updatedProduct.limite = editLimite
    }

    try {
      setEditDisable(true)
      // A rota de update, considerando que você use um ID para identificar o produto
      const updateUrl = `/products/${productId}`

      // Realiza a requisição de update
      await Api.put(updateUrl, updatedProduct, config)
      // setIsLoading(false)
      toast.success('Produto atualizado com sucesso!')
      setIsEditModalOpen(false)
      setSelectedItemIndex(null)
    } catch (error) {
      toast.error('Erro - Produto não foi atualizado!')
      setIsEditModalOpen(false)
    }
    getProducts()
  }

  const removeProduct = async (productId: number | null) => {
    setIsLoading(true)
    try {
      setRemDisable(true)
      // A rota de delete, considerando que você use um ID para identificar o produto
      const deleteUrl = `/products/${productId}`

      // Realiza a requisição de delete
      await Api.delete(deleteUrl, config)

      // setIsLoading(false)
      toast.success('Produto removido com sucesso!')
      setIsRemoveModalOpen(false)
      setSelectedItemIndex(null)
    } catch (error) {
      toast.error('Erro - Produto não foi removido!')
      setIsRemoveModalOpen(false)
    }
    getProducts()
  }

  // filtros

  const orderByName = (data: Salgado[], propertyName: string, ascending: boolean) => {
    return data.sort((a: Salgado, b: Salgado) => {
      const orderFactor = ascending ? 1 : -1
      const comparison =
        a[propertyName] > b[propertyName] ? 1 : a[propertyName] < b[propertyName] ? -1 : 0
      return comparison * orderFactor
    })
  }

  const orderByNextOrderQuantity = (data: Salgado[], ascending: boolean) => {
    return data.sort((a: Salgado, b: Salgado) => {
      const orderFactor = ascending ? 1 : -1
      const nextOrderQuantityA = a.limite - a.qtd <= 0 ? 0 : a.limite - a.qtd
      const nextOrderQuantityB = b.limite - b.qtd <= 0 ? 0 : b.limite - b.qtd
      return (nextOrderQuantityA - nextOrderQuantityB) * orderFactor
    })
  }

  const filterNextOrderQuantity = (propertyName: string) => {
    let sortedData

    if (propertyName === 'qtdNextOrder') {
      sortedData = orderByNextOrderQuantity([...estoque], ascendingOrder)
    } else {
      sortedData = orderByName([...estoque], propertyName, ascendingOrder)
    }

    setEstoque(sortedData)
    setAscendingOrder(!ascendingOrder)
  }

  const filterProductsAaZ = (propertyName: string) => {
    // Clonar o array antes de ordenar para evitar modificar o estado diretamente
    const clonedData = [...estoque]
    const sortedData = orderByName(clonedData, propertyName, ascendingOrder)
    setEstoque(sortedData)
    // Alternar a direção da ordenação para a próxima vez que o botão for clicado
    setAscendingOrder(!ascendingOrder)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [press, setPress] = useState(false)
  const [start, setStart] = useState(0)

  const handleMouseDown = () => {
    setPress(true)
    setStart(Date.now())
  }

  const handleMouseUp = () => {
    setPress(false)
    if (Date.now() - start > 500) {
      openEditModal()
    }
  }

  return (
    <>
      <Header />
      {/* <Footer /> */}
      <Container>
        <section className='estoque-section'>
          <h1>Estoque</h1>
          <div className='date'>
            <h1>{currentDate}</h1>
            <img src={calendario} alt='calendario' />
          </div>
        </section>
        <section className='tableControl'>
          <button type='button' className='add-btn' onClick={openAddModal}>
            <img src={Add} alt='adicionar produto' />
          </button>
          <button type='button' className='edit-btn' onClick={openEditModal}>
            <img src={Edit} alt='Editar produto' />
          </button>
          <button type='button' className='remove-btn' onClick={openRemoveModal}>
            <img src={Remove} alt='Remover produto' />
          </button>
        </section>

        <article>
          <section className='list-prop'>
            <div id='produto' onClick={() => filterProductsAaZ('name')}>
              <h2>Produtos</h2>
            </div>
            <div onClick={() => filterProductsAaZ('qtd')}>
              <h2>Qtd</h2>
              <h2>Estoque</h2>
            </div>
            <div onClick={() => filterProductsAaZ('limite')}>
              <h2>Limite</h2>
              <h2>mínimo</h2>
            </div>
            <div onClick={() => filterNextOrderQuantity('qtdNextOrder')}>
              <h2>Qtd Prox</h2>
              <h2>Ordem</h2>
            </div>
          </section>

          {isLoading ? (
            <Loading />
          ) : (
            <>
              {estoque.map((salgado) => (
                <section
                  key={salgado.id}
                  className={`list ${selectedItemIndex === salgado.id ? 'selected' : ''}`}
                  onClick={() => {
                    getOneProduct(salgado.id)
                    setSelectedItemIndex((prevIndex) =>
                      prevIndex === salgado.id ? null : salgado.id,
                    )
                  }}
                  onMouseDown={handleMouseDown}
                  onMouseUp={handleMouseUp}
                  onTouchStart={handleMouseDown}
                  onTouchEnd={handleMouseUp}
                >
                  <div>
                    <h2 className='nome'>{salgado.name}</h2>
                    <h4 className='sabor'>{salgado.sabor}</h4>
                  </div>
                  <div>
                    <h3>{salgado.qtd}</h3>
                  </div>
                  <div>
                    <h3>{salgado.limite}</h3>
                  </div>
                  <div>
                    <h3>{salgado.limite - salgado.qtd <= 0 ? 0 : salgado.limite - salgado.qtd}</h3>
                  </div>
                </section>
              ))}
            </>
          )}
        </article>
        {isAddModalOpen && (
          <div className='modal'>
            <div className='add-edit'>
              <h1>Adicionar Produto</h1>
              <form action='/processar-formulario' method='post'>
                <div>
                  <h2>Nome do Salgado</h2>
                  <input
                    type='text'
                    placeholder='Ex: Coxinha, Pastel'
                    onChange={(e) => setName(e.target.value)}
                    minLength={3}
                    required
                  />
                </div>
                <div>
                  <h2>Sabor</h2>
                  <input
                    type='text'
                    placeholder='Ex: Queijo, Frango'
                    onChange={(e) => setSabor(e.target.value)}
                    minLength={3}
                    required
                  />
                </div>
                <section>
                  <div>
                    <h2>Quantidade</h2>
                    <input
                      type='number'
                      placeholder='Ex: 200'
                      onChange={(e) => setQtd(Number(e.target.value))}
                      min='1'
                      step='1'
                      required
                    />
                  </div>
                  <div>
                    <h2>Limite</h2>
                    <input
                      type='number'
                      placeholder='Ex: 300'
                      onChange={(e) => setLimite(Number(e.target.value))}
                      min='1'
                      step='1'
                      required
                    />
                  </div>
                </section>
              </form>
              <section className='buttons'>
                <button onClick={addProduct} className='add-button' disabled={addDisable}>
                  <img src={Add} alt='Adicionar' />
                  SIM
                </button>
                <button onClick={closeAddModal}>NÃO</button>
              </section>
            </div>
          </div>
        )}
        {isEditModalOpen && (
          <div className='modal'>
            <div className='add-edit'>
              <h1>Editar Produto</h1>
              <form action='/processar-formulario' method='post'>
                <div>
                  <h2>Nome do Salgado</h2>
                  <input
                    type='text'
                    placeholder='Coxinha'
                    onChange={(e) => setEditName(e.target.value)}
                    minLength={3}
                    defaultValue={product.name}
                    required
                  />
                </div>
                <div>
                  <h2>Sabor</h2>
                  <input
                    type='text'
                    placeholder='Queijo'
                    onChange={(e) => setEditSabor(e.target.value)}
                    minLength={3}
                    defaultValue={product.sabor}
                    required
                  />
                </div>
                <section>
                  <div>
                    <h2>Quantidade</h2>
                    <input
                      type='number'
                      placeholder='200'
                      onChange={(e) => setEditQtd(Number(e.target.value))}
                      defaultValue={product.qtd}
                      min='1'
                      step='1'
                      required
                    />
                  </div>
                  <div>
                    <h2>Limite</h2>
                    <input
                      type='number'
                      placeholder='300'
                      onChange={(e) => setEditLimite(Number(e.target.value))}
                      defaultValue={product.limite}
                      min='1'
                      step='1'
                      required
                    />
                  </div>
                </section>
              </form>
              <section className='buttons'>
                <button
                  onClick={() => updateProduct(selectedItemIndex)}
                  className='edit-button'
                  disabled={editDisable}
                >
                  <img src={Edit} alt='EDITAR' />
                  SIM
                </button>
                <button onClick={closeEditModal}>NÃO</button>
              </section>
            </div>
          </div>
        )}
        {isRemoveModalOpen && (
          <div className='modal'>
            <div className='remove-modal'>
              <section className='info'>
                <h2>Deseja apagar?</h2>
                <div>
                  <h2>{product.name}</h2>
                  <h3>{product.sabor}</h3>
                </div>
              </section>
              <section className='buttons'>
                <button
                  onClick={() => removeProduct(selectedItemIndex)}
                  className='remove-button'
                  disabled={remDisable}
                >
                  <img src={Remove} alt='RemoveAR' />
                  SIM
                </button>
                <button onClick={closeRemoveModal}>NÃO</button>
              </section>
            </div>
          </div>
        )}
      </Container>
      <ToastContainer />
    </>
  )
}
