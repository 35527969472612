import { Routes, Route } from 'react-router-dom'

import Login from './pages/login'
import Estoque from './pages/estoque'
import NotFound from './components/NotFound'
import Users from './pages/users'
import Entrada from './pages/entrada'
import Saida from './pages/saida'

export function AppRoutes() {
  return (
    <Routes>
      <Route path='/' element={<Login />} />
      <Route path='/estoque' element={<Estoque />} />
      <Route path='/entrada' element={<Entrada />} />
      <Route path='/saida' element={<Saida />} />
      <Route path='/users' element={<Users />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  )
}
