import styled from 'styled-components'

export const Container = styled.header`
  position: fixed;
  display: flex;
  justify-content: space-between;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #950100;
  height: 4rem;
  color: #fff;
  padding: 10px;
  z-index: 999;

  img {
    width: 4rem;
    cursor: pointer;

    &:active {
      scale: 0.95;
    }
  }

  .menu {
    width: 2rem;
  }
  .menu-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: -80%;
    width: 80%;
    height: 100%;
    transition: left 0.3s ease;
    z-index: 999;
    background-color: #fcf2ea;
    color: #950100;

    h1 {
      margin-top: 30px;
      margin-bottom: 10px;
      margin-left: 15px;
      text-align: left;
      align-self: flex-start;
    }

    h2 {
      margin: 10px 20px;
      text-align: right;
      align-self: flex-end;
    }

    img {
      width: 70%;
    }

    div {
      align-items: center;
      border: #950100 2px solid;
      border-radius: 5px;
      display: flex;
      justify-content: flex-start;
      padding: 10px 10px;
      margin-bottom: 10px;
      width: 90%;

      &:hover {
        opacity: 0.9;
      }

      &:active {
        scale: 0.95;
      }

      img {
        margin-right: 7px;
        width: 1rem;
      }

      .arrow {
        width: 1rem;
      }
      .arrow-down {
        transform: rotate(180deg);
      }
    }
  }

  .menu-container.open {
    left: 0;
  }

  .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6); /* Cor de fundo com transparência */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999; /* Z-index alto para sobrepor o conteúdo principal */

    section {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 50%;
      height: 30%;
      background-color: #fff; /* Fundo branco */
      color: #000; /* Cor do texto preta */
      padding: 20px;
      border-radius: 15px;

      div {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }

      h1 {
        text-align: center;
      }

      /* h3 {
        text-align: left;
      } */

      label {
        align-items: center;
        display: flex;
        justify-content: space-between;

        input {
          border-radius: 5px;
          padding: 5px;
          height: 50px;
          width: 150px;
          font-size: 2rem;
        }
      }
    }

    /* .close-menu {
      display: contents;
    } */

    .modal2 {
      height: 40%;
      width: 50%;
      justify-content: space-between;

      h1 {
        margin: 0;
      }
    }

    .modal3 {
      height: 80%;
      width: 55%;
      justify-content: space-between;

      h1 {
        margin: 0;
      }
    }

    .buttons {
      height: 4.4rem !important;
      display: flex;
      flex-direction: row !important;
      margin-top: 0;
      padding: 0;

      button {
        color: #fff;
        height: 3.5rem;
        border: none;
        border-radius: 5px;
        background-color: #333;
        font-size: 1.5rem;
        font-weight: bold;
        width: 48%;

        &:hover {
          opacity: 0.9;
        }

        &:active {
          scale: 0.95;
        }

        img {
          margin-right: 10px;
          width: 1.6rem;
        }
      }

      .sim {
        background-color: #950100;
      }

      .reverter {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 699px) and (orientation: portrait) {
    .menu-container {
      width: 75%; /* Ou qualquer outra largura desejada para dispositivos móveis */
    }

    .modal {
      section {
        width: 90%;
      }

      .modal2 {
        h1 {
          margin-top: 10px;
        }
        width: 90%;
      }

      .modal3 {
        h1 {
          margin-top: 10px;
        }
        width: 90%;
      }
    }

    /* Outros estilos específicos para dispositivos móveis */
  }

  @media only screen and (min-width: 700px) {
    .menu-container {
      width: 45%;
    }
  }

  @media only screen and (min-width: 1200px) {
    .menu-container {
      width: 25%;
    }
  }
`
