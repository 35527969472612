import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  html, body, #root {
    min-height: 100%;
    font-family: 'Poppins', sans-serif;
  }

  /* #root {
    background-color: #FCF2EA;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  } */

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  main {
    background-color: #FCF2EA;
  }
  
`
