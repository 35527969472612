import React, { useState, useEffect, useContext } from 'react'
import Header from '../../components/Header'
// import Footer from '../../components/Footer'
import Loading from '../../components/Loading'
import { Container } from './styles'
import Add from '../../assets/add.svg'
import Edit from '../../assets/edit.svg'
import calendario from '../../assets/calendario.png'
import Api from '../../services/api'
import { UserContext } from '../../context/userContext'
import { Salgado } from '../estoque'
import { startOfWeek, endOfWeek, format, isWithinInterval, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import Remove from '../../assets/remove.svg'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

interface ProductInfo {
  name: string
  sabor: string
}

interface ProductParcial {
  id: number
  entrada: number
  qtd: number
  createdAt: Date
  updatedAt: Date
}

interface Orders {
  id: number
  Product: ProductInfo
  productions: ProductParcial[]
  qtd: number
  origem: string
  status: string
  createdAt: Date
  updatedAt: Date
  [key: string]: string | number | Date | ProductInfo | ProductParcial[] // Adicionei ProductParcial[]
}

interface OrdemItem {
  id: number
  product: number
  qtd: number
  createdAt: Date
  updatedAt: Date
  Product: ProductInfo
}

type OrdemGetAllArray = [OrdemItem, ProductParcial[]]

const estadoInicial: OrdemGetAllArray = [
  {
    id: 0,
    product: 0,
    qtd: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
    Product: {
      name: '',
      sabor: '',
    },
  },
  [], // ou qualquer valor inicial para o array vazio
]

const today = new Date()
const startOfCurrentWeek = startOfWeek(today, { weekStartsOn: 0 })
const endOfCurrentWeek = endOfWeek(today, { weekStartsOn: 0 })

export default function Entrada() {
  const [isLoading, setIsLoading] = useState(true)
  // const [currentDate, setCurrentDate] = useState('')
  const [estoque, setEstoque] = useState<Salgado[]>([])
  const [ordem, setOrdem] = useState<Orders[]>([])
  const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(null)
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)
  // const [selectedRadio, setSelectedRadio] = useState<string>('criado')
  const [product, setProduct] = useState(0)
  const [qtd, setQtd] = useState(0)
  const [qtdEdit, setQtdEdit] = useState(0)
  const [qtdEdit2, setQtdEdit2] = useState(0)
  // const [editStatus, setEditStatus] = useState('NÃO INICIADA')
  // const [order, setOrder] = useState<Orders>(initialOrder)
  const [order, setOrder] = useState<OrdemGetAllArray>(estadoInicial)
  // const [verifyOrder, setVerifyOrder] = useState<Orders[]>([])
  // filtros
  const [ascendingOrder, setAscendingOrder] = useState<boolean>(false)
  const [selectedDate, setSelectedDate] = useState(startOfCurrentWeek)
  // disable 3 buttons
  const [addDisable, setAddDisable] = useState<boolean>(true)
  // const [editDisable, setEditDisable] = useState<boolean>(true)
  const [remDisable, setRemDisable] = useState<boolean>(true)
  // const [selectedDate, setSelectedDate] = useState(new Date())
  const [selectedDate2, setSelectedDate2] = useState(endOfCurrentWeek)

  const userContext = useContext(UserContext)

  // Verifica se o contexto de usuário está definido antes de acessar suas propriedades
  if (!userContext) {
    throw new Error('UserContext is undefined')
  }

  const { user } = userContext

  const config = {
    headers: {
      Authorization: user.token,
    },
  }

  useEffect(() => {
    getOrder()
    getProducts()
  }, [isAddModalOpen, isEditModalOpen, user])

  const formatCustomDate = (date: Date | string) => {
    const parsedDate = typeof date === 'string' ? parseISO(date) : date

    // Formata o dia e o mês no formato 'DD/MM'
    return format(parsedDate, 'dd/MM/yy', { locale: ptBR })
  }

  const formatCustomDateFilter = (date: Date | string, formatString: string) => {
    const parsedDate = typeof date === 'string' ? parseISO(date) : date
    return format(parsedDate, formatString, { locale: ptBR })
  }

  const setSelectedDateState = async (date: Date) => {
    // Zera as horas, minutos, segundos e milissegundos
    date.setHours(0, 0, 0, 0)
    selectedDate2.setHours(23, 59, 59, 999) // Define a última milissegundo do dia

    setSelectedDate(date)

    const { data } = await Api.get('/entradas', config)

    const dadosDaSemanaAtual: Orders[] = data.filter((item: Orders) => {
      const dataItem = new Date(item.createdAt)
      dataItem.setHours(0, 0, 0, 0)

      // Verifica se a dataItem está dentro do intervalo
      return dataItem >= date && dataItem <= selectedDate2
    })

    setOrdem(dadosDaSemanaAtual)
  }

  const setSelectedDate2State = async (date: Date) => {
    setSelectedDate2(date)
    // Zera as horas, minutos, segundos e milissegundos
    date.setHours(0, 0, 0, 0)

    const { data } = await Api.get('/entradas', config)

    const dadosDaSemanaAtual: Orders[] = data.filter((item: Orders) => {
      const dataItem = new Date(item.createdAt)
      dataItem.setHours(0, 0, 0, 0)

      // Verifica se a dataItem está dentro do intervalo
      return dataItem >= selectedDate && dataItem <= date
    })

    setOrdem(dadosDaSemanaAtual)
  }

  const getOrder = async () => {
    try {
      const { data } = await Api.get('/entradas', config)

      // const startOfCurrentWeek = new Date(
      //   'Sun Jan 9 2024 00:00:00 GMT-0300 (Horário Padrão de Brasília)',
      // )
      // const endOfCurrentWeek = new Date(
      //   'Sun Jan 15 2024 00:00:00 GMT-0300 (Horário Padrão de Brasília)',
      // )

      const dadosDaSemanaAtual = data.filter((item: Orders) => {
        const dataItem = new Date(item.createdAt)

        return isWithinInterval(dataItem, { start: startOfCurrentWeek, end: endOfCurrentWeek })
      })

      dadosDaSemanaAtual.sort(
        (a: Orders, b: Orders) =>
          new Date(b.createdAt as Date).getTime() - new Date(a.createdAt as Date).getTime(),
      )

      setOrdem(dadosDaSemanaAtual)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      // Trate o erro conforme necessário, como exibindo uma mensagem de erro para o usuário.
    }
  }

  const getProducts = async () => {
    try {
      const { data } = await Api.get('/products', config)
      // Ordena os dados primeiro pelo nome e depois pelo sabor
      const sortedData = data.sort((a: Salgado, b: Salgado) => {
        const nameComparison = a.name.localeCompare(b.name)
        // Se os nomes são iguais, compare pelo campo 'sabor'
        return nameComparison !== 0 ? nameComparison : a.sabor.localeCompare(b.sabor)
      })

      setEstoque(sortedData)
      // setIsLoading(false)
    } catch (error) {
      // setIsLoading(false)
      // toast.error('Erro ao obter dados do estoque')
      // console.error('Erro ao obter dados do estoque:', error)
      // Trate o erro conforme necessário, como exibindo uma mensagem de erro para o usuário.
    }
  }

  const openAddModal = () => {
    setAddDisable(false)
    setIsAddModalOpen(true)
  }

  const closeAddModal = () => {
    setIsAddModalOpen(false)
  }

  const openEditModal = () => {
    // handleRadioClick('')
    if (selectedItemIndex === null) {
      toast.warning('Selecione uma ordem')
    } else {
      // setEditDisable(false)
      setIsEditModalOpen(true)
    }
  }

  const closeEditModal = () => {
    setIsEditModalOpen(false)
    // handleRadioClick('')
  }

  const openRemoveModal = () => {
    if (selectedItemIndex === null) {
      toast.warning('Selecione uma ordem')
    } else {
      if (user.permission !== 1) {
        toast.info('Você precisa ser um administrador')
      } else {
        setRemDisable(false)
        setIsRemoveModalOpen(true)
      }
    }
    getOneOrder(selectedItemIndex)
  }

  const closeRemoveModal = () => {
    setIsRemoveModalOpen(false)
  }

  // const handleRadioClick = (value: string) => {
  //   setSelectedRadio(value)
  // }

  const cleanFieldOrders = () => {
    setProduct(0)
    setQtd(0)
  }

  const getOneOrder = async (orderId: number | null) => {
    const order = `/entradas/${orderId}`

    try {
      const { data } = await Api.get(order, config)
      setOrder(data)
      console.log(data)

      setIsLoading(false)
    } catch (error) {
      // console.error('Erro ao obter dados do estoque:', error)
      // Trate o erro conforme necessário, como exibindo uma mensagem de erro para o usuário.
    }
  }

  const addOrder = async () => {
    // Verificar se todos os campos atendem às condições
    if (product !== null && qtd !== 0) {
      // Criar o objeto newOrder apenas se a validação passar
      const newOrder = { product, qtd }

      // setIsLoading(true); // Se necessário reativar a carga

      try {
        setAddDisable(true)
        await Api.post('/entradas', newOrder, config)
        setIsAddModalOpen(false)
        // setIsLoading(false);
        toast.success('Entrada adicionada com sucesso!')
        setSelectedItemIndex(null)
        // console.log(data)
      } catch (error) {
        setIsAddModalOpen(false)
        // setIsLoading(false);
        toast.error('Erro - Entrada não adicionada!')
        // console.log(error)
      }

      getOrder()
      cleanFieldOrders()
    } else {
      // Tratar o caso em que pelo menos um dos campos não atende às condições
      toast.error('Por favor, preencha todos os campos corretamente.')
    }
  }

  const updateProduct = async (productionId: number, productId: number) => {
    const updatedProduct = { qtd: 0, entrada: productionId }
    const updatedProduct2 = { qtd: 0 }

    // if (user.permission !== 1) {
    //   return toast.info('Você precisa ser um administrador')
    // }

    if (qtdEdit2 !== 0) {
      updatedProduct.qtd = qtdEdit2
    }

    if (updatedProduct.qtd === 0) {
      return toast.error('Preencha corretamente o campo')
    }

    // if (updatedProduct2.qtd === 0) {
    //   return toast.error('Preencha corretamente o campo')
    // }

    const updateUrl2 = `/products/${productId}`
    const { data } = await Api.get(updateUrl2, config)

    updatedProduct2.qtd = data.qtd + qtdEdit2

    try {
      const updateUrl = `/production`

      await Api.post(updateUrl, updatedProduct, config)

      toast.success('Produção diária adicionada!')

      setIsEditModalOpen(false)
      setIsEditModalOpen(true)
      setSelectedItemIndex(null)
    } catch (error) {
      toast.error('Erro - Produção diária não foi adicionada!')
    }

    try {
      await Api.put(updateUrl2, updatedProduct2, config)
      toast.success('Estoque atualizado!')
    } catch (error) {
      toast.error('Erro - Estoque não foi atualizado')
    }
    setQtdEdit2(0)
    getOrder()
    const order = `/entradas/${productionId}`
    const getOr = await Api.get(order, config)
    setOrder(getOr.data)
  }

  const updateOrder = async (orderId: number | null) => {
    const updatedOrder = { qtd: 0 }

    if (user.permission !== 1) {
      return toast.info('Você precisa ser um administrador')
    }

    if (qtdEdit !== 0) {
      updatedOrder.qtd = qtdEdit
    }

    if (updatedOrder.qtd === 0) {
      return toast.error('Preencha corretamente o campo')
    }

    // console.log(updatedOrder)
    // console.log(qtdEdit)

    try {
      // A rota de update, considerando que você use um ID para identificar o produto
      const updateUrl = `/entradas/${orderId}`

      // Realiza a requisição de update
      await Api.put(updateUrl, updatedOrder, config)

      setIsLoading(false)
      toast.success('Quantidade atualizada com sucesso!')

      setIsEditModalOpen(false)
      setIsEditModalOpen(true)
      setSelectedItemIndex(null)
    } catch (error) {
      setIsLoading(false)
      toast.error('Erro - Quantidade não atualizada!')
      setIsEditModalOpen(false)
      // console.log(error)
    }
    setQtdEdit(0)
    getOrder()
    const order = `/entradas/${orderId}`
    const { data } = await Api.get(order, config)
    setOrder(data)
  }

  const removeProduction = async (
    orderId: number | null,
    productId: number,
    item: number,
    productionId: number,
  ) => {
    const updatedProduct2 = { qtd: 0 }
    const updateUrl2 = `/products/${productId}`
    const { data } = await Api.get(updateUrl2, config)

    if (user.permission !== 1) {
      return toast.info('Você precisa ser um administrador')
    }

    if (data.qtd - item <= 0) {
      updatedProduct2.qtd = 0
    } else {
      updatedProduct2.qtd = data.qtd - item
    }
    try {
      setRemDisable(true)
      // A rota de delete, considerando que você use um ID para identificar o produto
      const deleteUrl = `/production/${orderId}`

      // Realiza a requisição de delete
      await Api.delete(deleteUrl, config)
      await Api.put(updateUrl2, updatedProduct2, config)
      setIsLoading(false)
      toast.success('Produção diária removida com sucesso!')
      // setIsEditModalOpen(false)
      setSelectedItemIndex(null)
    } catch (error) {
      setIsLoading(false)
      toast.error('Erro - Produção diária não removida!')
      setIsRemoveModalOpen(false)
    }
    getOrder()
    const order = `/entradas/${productionId}`
    const getOr = await Api.get(order, config)
    setOrder(getOr.data)
  }

  const removeOrder = async (orderId: number | null) => {
    setIsLoading(true)
    try {
      setRemDisable(true)
      // A rota de delete, considerando que você use um ID para identificar o produto
      const deleteUrl = `/entradas/${orderId}`

      // Realiza a requisição de delete
      await Api.delete(deleteUrl, config)

      setIsLoading(false)
      toast.success('Entrada removida com sucesso!')
      setIsRemoveModalOpen(false)
      setSelectedItemIndex(null)
    } catch (error) {
      setIsLoading(false)
      toast.error('Erro - Entrada não removida!')
      setIsRemoveModalOpen(false)
    }
    getOrder()
  }

  // filtros

  const orderByName = (data: Orders[], propertyName: string, ascending: boolean) => {
    return data.sort((a: Orders, b: Orders) => {
      const orderFactor = ascending ? 1 : -1

      // Obtém os valores das propriedades
      const valueA =
        propertyName === 'Product' ? `${a.Product.name} ${a.Product.sabor}` : a[propertyName]
      const valueB =
        propertyName === 'Product' ? `${b.Product.name} ${b.Product.sabor}` : b[propertyName]

      // Converte os valores para strings para comparação lexicográfica
      const stringA = String(valueA)
      const stringB = String(valueB)

      // Converte os valores para números se forem números
      const numericA = !isNaN(Number(valueA)) ? Number(valueA) : null
      const numericB = !isNaN(Number(valueB)) ? Number(valueB) : null

      // Realiza a comparação
      if (numericA !== null && numericB !== null) {
        // Se ambos forem números, compara numericamente
        return (numericA - numericB) * orderFactor
      } else {
        // Se pelo menos um não for número, compara lexicograficamente
        return stringA.localeCompare(stringB) * orderFactor
      }
    })
  }

  const orderByFormattedDate = (
    data: Orders[],
    propertyName: string,
    formatString: string,
    ascending: boolean,
  ) => {
    return data.sort((a: Orders, b: Orders) => {
      const orderFactor = ascending ? 1 : -1

      // Obtém os valores das propriedades
      const dateA =
        typeof a[propertyName] === 'string' ? parseISO(a[propertyName] as string) : a[propertyName]
      const dateB =
        typeof b[propertyName] === 'string' ? parseISO(b[propertyName] as string) : b[propertyName]

      // Formata as datas
      const formattedDateA = formatCustomDateFilter(dateA as Date, formatString)
      const formattedDateB = formatCustomDateFilter(dateB as Date, formatString)

      // Converte as datas formatadas para milissegundos
      const timeA = new Date(formattedDateA).getTime()
      const timeB = new Date(formattedDateB).getTime()

      // Realiza a comparação
      return (timeA - timeB) * orderFactor
    })
  }

  const filterOrdersByCreatedAt = () => {
    const clonedData = [...ordem]
    const sortedData = orderByFormattedDate(
      clonedData,
      'createdAt',
      'yyyy-MM-dd HH:mm:ss',
      ascendingOrder,
    )
    setOrdem(sortedData)
    setAscendingOrder(!ascendingOrder)
  }

  const filterProductsAaZ = (propertyName: string) => {
    // Clonar o array antes de ordenar para evitar modificar o estado diretamente
    const clonedData = [...ordem]
    const sortedData = orderByName(clonedData, propertyName, ascendingOrder)
    setOrdem(sortedData)
    // Alternar a direção da ordenação para a próxima vez que o botão for clicado
    setAscendingOrder(!ascendingOrder)
  }

  const formatDate = (date: Date) => {
    const brtDate = new Date(date.toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' }))
    const year = brtDate.getFullYear()
    const month = String(brtDate.getMonth() + 1).padStart(2, '0')
    const day = String(brtDate.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [press, setPress] = useState(false)
  const [start, setStart] = useState(0)

  const handleMouseDown = () => {
    setPress(true)
    setStart(Date.now())
  }

  const handleMouseUp = () => {
    setPress(false)
    if (Date.now() - start > 500) {
      openEditModal()
    }
  }

  return (
    <>
      <Header />
      {/* <Footer /> */}
      <Container>
        <section id='inicial'>
          <h1>Entradas</h1>
          <div className='date2'>
            <h1>{formatCustomDate(today)}</h1>
            <img src={calendario} alt='calendario' />
          </div>
        </section>
        <section className='week'>
          <input
            type='date'
            className='inputdate2 adidate'
            value={formatDate(selectedDate)}
            onChange={(e) => {
              const selectedDate = new Date(e.target.value + 'T00:00:00')
              const localSelectedDate = new Date(
                selectedDate.toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' }),
              )
              setSelectedDateState(localSelectedDate)
            }}
          />
          <input
            type='date'
            className='inputdate2'
            value={formatDate(selectedDate2)}
            onChange={(e) => {
              const selectedDate = new Date(e.target.value + 'T23:59:59')
              const localSelectedDate = new Date(
                selectedDate.toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' }),
              )
              setSelectedDate2State(localSelectedDate)
            }}
          />
        </section>
        <section className='orderControl'>
          <button type='button' className='add-order' onClick={openAddModal}>
            <img src={Add} alt='adicionar ordem' />
            <h3>Ordem</h3>
          </button>
          <button type='button' className='edit-order' onClick={openEditModal}>
            <img src={Edit} alt='Editar ordem' />
            <h3>Status</h3>
          </button>
          <button type='button' className='delete-order' onClick={openRemoveModal}>
            <img src={Remove} alt='Remover ordem' />
            <h3>Ordem</h3>
          </button>
        </section>
        <article className='table'>
          <section className='list-prod'>
            <div className='number' onClick={() => filterProductsAaZ('id')}>
              <h2>Nº</h2>
            </div>
            <div className='produto' onClick={() => filterProductsAaZ('Product')}>
              <h2>Produto</h2>
            </div>
            <div className='qtd' onClick={() => filterProductsAaZ('qtd')}>
              <h2>Qtd</h2>
            </div>
            <div className='create' onClick={() => filterOrdersByCreatedAt()}>
              <h2>Data</h2>
            </div>
          </section>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {ordem.map((order) => (
                <section
                  key={order.id}
                  className={`list ${selectedItemIndex === order.id ? 'selected' : ''}`}
                  onClick={() => {
                    getOneOrder(order.id)
                    setSelectedItemIndex((prevIndex) => (prevIndex === order.id ? null : order.id))
                  }}
                  onMouseDown={handleMouseDown}
                  onMouseUp={handleMouseUp}
                  onTouchStart={handleMouseDown}
                  onTouchEnd={handleMouseUp}
                >
                  <div className='info-entradas'>
                    <div className='number'>
                      <h3>{order.id}</h3>
                    </div>
                    <div className='product-div'>
                      <h2 className='nome'>{order.Product.name}</h2>
                      <h4 className='sabor'>{order.Product.sabor}</h4>
                    </div>
                    <div className='qtd'>
                      <h3>{order.qtd}</h3>
                    </div>
                    <div className='create'>
                      <h3>{formatCustomDate(order.createdAt)}</h3>
                    </div>
                  </div>
                  <div className='info-parcial'>
                    <section className='parciais'>
                      {order.productions.map((item) => (
                        <div key={item.id}>
                          <h4>{item.qtd}</h4>
                        </div>
                      ))}
                    </section>

                    <section className='colors'>
                      {/* Verifique se a soma é menor que order.qtd */}
                      {order.productions &&
                      order.productions.reduce((acc, item) => acc + item.qtd, 0) < order.qtd ? (
                        // Se a soma for menor, exiba order.qtd - soma
                        <h4>
                          {order.qtd - order.productions.reduce((acc, item) => acc + item.qtd, 0)}
                        </h4>
                      ) : (
                        // Caso contrário, exiba "OK"
                        <h4 className='ok'>OK !</h4>
                      )}
                    </section>
                  </div>
                </section>
              ))}
            </>
          )}
        </article>
        {isAddModalOpen && (
          <div className='modal'>
            <div className='add-edit'>
              <h1>Nova Entrada</h1>
              <form action='/processar-formulario' method='post'>
                <label htmlFor='produto'>
                  <select
                    id='produto'
                    name='produto'
                    onChange={(e) => setProduct(Number(e.target.value))}
                    required
                  >
                    <option value='null'>Escolha o Salgado</option>
                    {estoque.map((salgado) => (
                      <option
                        value={salgado.id}
                        key={salgado.id}
                      >{`${salgado.name} - ${salgado.sabor}`}</option>
                    ))}
                  </select>
                </label>

                <label htmlFor='QUANTIDADE' className='qtd'>
                  <h3>Quantidade</h3>
                  <input
                    type='number'
                    name='qtd'
                    id='qtd'
                    min='1'
                    step='1'
                    placeholder='Ex: 200'
                    onChange={(e) => setQtd(Number(e.target.value))}
                  />
                </label>
              </form>
              <section className='buttons'>
                <button onClick={addOrder} className='add-button' disabled={addDisable}>
                  <img src={Add} alt='Adicionar' />
                  SIM
                </button>
                <button onClick={closeAddModal}>NÃO</button>
              </section>
            </div>
          </div>
        )}
        {isEditModalOpen && (
          <div className='modal'>
            <div className='edit'>
              <h1>Status da Entrada</h1>
              <section className='section-big'>
                <section className='info-section' id='infoo'>
                  <h2>Entrada de Nº {order[0].id}</h2>
                  <h2>Data de Criação {formatCustomDate(order[0].createdAt)}</h2>
                  <h2>Nome: {order[0].Product.name}</h2>
                  <h2>Sabor: {order[0].Product.sabor}</h2>
                  <h2>Quantidade Atual: {order[0].qtd}</h2>
                </section>
                <section className='info-section one'>
                  <div className='qtd-box-status'>
                    <h2 className='qtd-status'>Quantidade:</h2>
                    <div>
                      <input
                        type='number'
                        name='qtdEdit'
                        id='qtdEdit'
                        min='1'
                        step='1'
                        onChange={(e) => setQtdEdit(Number(e.target.value))}
                      />
                      <div
                        className='add-box editar'
                        onClick={() => {
                          updateOrder(order[0].id)
                        }}
                      >
                        <img src={Edit} alt='editar' />
                      </div>
                    </div>
                  </div>
                  <div className='qtd-box-status down'>
                    <h2 className='qtd-status'>Produção:</h2>
                    <div>
                      <input
                        type='number'
                        name='qtdEdit2'
                        id='qtdEdit2'
                        min='1'
                        step='1'
                        onChange={(e) => setQtdEdit2(Number(e.target.value))}
                      />
                      <div
                        className='add-box'
                        onClick={() => {
                          updateProduct(order[0].id, order[0].product)
                        }}
                      >
                        <img src={Add} alt='adicionar' />
                      </div>
                    </div>
                  </div>
                  {order[1].map((item) => (
                    <div key={item.id} className='produc'>
                      <h2>{item.qtd} </h2>
                      <h2>{formatCustomDate(item.createdAt)} </h2>
                      <div
                        className='add-box'
                        onClick={() => {
                          removeProduction(item.id, order[0].product, item.qtd, order[0].id)
                        }}
                      >
                        <img src={Remove} alt='remover' />
                      </div>
                    </div>
                  ))}
                </section>
              </section>
              <section className='buttons'>
                <button onClick={closeEditModal} className='fechar'>
                  Fechar
                </button>
              </section>
            </div>
          </div>
        )}
        {isRemoveModalOpen && (
          <div className='modal'>
            <div className='remove-modal'>
              <section className='info'>
                <h1>Deseja apagar ?</h1>
                <section className='info2'>
                  <h2>Entrada de Nº {order[0].id}</h2>
                  <h2>Data de Criação {formatCustomDate(order[0].createdAt)}</h2>
                  <h2>Nome: {order[0].Product.name}</h2>
                  <h2>Sabor: {order[0].Product.sabor}</h2>
                  <h2>Quantidade: {order[0].qtd}</h2>
                  <h2 className='prod-parciais'>Produções Parciais ↓</h2>
                  {order[1].map((item) => (
                    <div key={item.id}>
                      <h2>{item.qtd} </h2>
                      <h2>{formatCustomDate(item.createdAt)} </h2>
                    </div>
                  ))}
                </section>
              </section>
              <section className='buttons'>
                <button
                  onClick={() => removeOrder(selectedItemIndex)}
                  className='remove-button'
                  disabled={remDisable}
                >
                  <img src={Remove} alt='RemoveAR' />
                  SIM
                </button>
                <button onClick={closeRemoveModal}>NÃO</button>
              </section>
            </div>
          </div>
        )}
      </Container>
      <ToastContainer />
    </>
  )
}
