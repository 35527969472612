import styled from 'styled-components'
import calendarioIcon from '../../assets/calendario.png'
import calendar from '../../assets/calendar.png'

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  section {
    align-items: center;
    display: flex;
    /* height: 3rem; */
    justify-content: space-between;
    margin-top: 75px;
    margin-bottom: 15px;
    padding: 0 10px;
    width: 100%;
  }

  #inicial {
    padding: 0;
    width: 95%;
    height: 3rem;
    margin-bottom: 5px;

    .date2 {
      align-items: center;
      display: flex;
      h1 {
        font-size: 1.5rem;
      }
      img {
        margin-left: 15px;
        height: 2rem;
        width: 2rem;
      }
    }
  }

  .week {
    margin: 0;
    margin-bottom: 15px;
    background-color: #282829;
    border-radius: 5px;
    width: 95%;

    input {
      width: 45%;
      background-color: #282829;
      color: #fff;
      border: none;
      height: 80%;
      font-size: 1.2rem;
      font-weight: bold;
      padding: 10px 5px;
      border-radius: 5px;
    }

    input:focus {
      outline: none; /* Remove a borda padrão do navegador */
      border: 3px solid #282829;
    }

    /* Estilo padrão para o ícone */
    .inputdate2::-webkit-calendar-picker-indicator {
      background-color: transparent;
      color: #00a859;
      background-image: url(${calendar});
      background-size: cover;
      width: 1.6rem;
      height: 1.6rem;
      margin-bottom: 4px;
    }

    /* Estilo adicional para navegadores baseados em Firefox */
    /* .inputdate2[type='date'] {
      width: 100%; 
    } */

    /* .inputdate2[type='date']::-moz-calendar-picker-indicator {
      background-color: transparent;
      background-image: url(${calendarioIcon});
      background-size: cover;
      width: 24px; 
      height: 24px; 
    } */
  }

  h1 {
    font-size: 1%.75;
  }

  h3 {
    margin-left: 10px;
  }

  .date {
    align-items: center;
    display: flex;
    width: 175px;
    h1 {
      font-size: 1.5rem;
    }
    img {
      /* display: none; */
      margin-left: 5px;
      height: 2rem;
      width: 2rem;
    }

    input {
      background-color: #fcf2ea;
      font-weight: bolder;
      height: 2.5rem;
      font-size: 1.5rem;
      border: 0;
      width: 100%;

      &:focus {
        border-color: #950100; /* Cor da borda quando em foco */
        border-radius: 5px;
        outline: none; /* Remove a borda padrão do navegador ao focar */
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5), 0 0 8px rgba(148, 1, 0, 0.5); /* Sombra para destacar o input */
      }
    }

    /* Estilo padrão para o ícone */
    .inputdate::-webkit-calendar-picker-indicator {
      background-color: transparent;
      background-image: url(${calendarioIcon}); /* Substitua 'seu_icone.png' pelo caminho do seu ícone */
      background-size: cover;
      width: 2rem; /* Largura desejada */
      height: 2rem; /* Altura desejada */
    }

    /* Estilo adicional para navegadores baseados em Firefox */
    .inputdate[type='date'] {
      width: 100%; /* Certifique-se de que o seletor ocupa toda a largura disponível */
    }

    .inputdate[type='date']::-moz-calendar-picker-indicator {
      background-color: transparent;
      background-image: url(${calendarioIcon}); /* Substitua 'seu_icone.png' pelo caminho do seu ícone */
      background-size: cover;
      width: 24px; /* Largura desejada */
      height: 24px; /* Altura desejada */
    }
  }

  .orderControl {
    margin-top: 0;
    margin-bottom: 15px;
    width: 95%;
    padding: 0;
    justify-content: space-between;

    div {
      border: #ccc solid 1px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      cursor: pointer;
      padding: 2px 3px;

      &:hover {
        opacity: 0.9;
      }

      &:active {
        scale: 0.95;
      }
    }
    #filter {
      width: 2rem;
    }

    button {
      align-items: center;
      display: flex;
      justify-content: center;
      border: none;
      border-radius: 5px;
      color: #fff;
      cursor: pointer;
      height: 3rem;
      width: 30%;

      &:hover {
        opacity: 0.9;
      }

      &:active {
        scale: 0.95;
      }
    }

    .add-order {
      background-color: #00a859;
    }

    .edit-order {
      background-color: #282829;
    }

    .delete-order {
      background-color: #ed3237;
    }
  }

  .list-prod {
    height: 3rem;
    background-color: #950100;
    border: 1px solid #950100;
    color: #fff;
    margin: 0;
    padding: 0;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25%;
    }

    .produto {
      width: 45%;
    }

    .number {
      width: 10%;
    }

    .qtd {
      width: 20%;
    }

    h2 {
      font-size: 1rem;
      text-align: center;
    }
  }

  .table {
    align-items: center;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 5px solid #950100;
    border-radius: 5px;
    color: #fff;
    width: 95%;
    min-height: 500px;
    margin-bottom: 5rem;

    .list {
      flex-direction: column;
      background-color: #fff;
      color: #950100;
      margin: 0;
      height: 6.5rem;
      /* border-bottom: 1px solid #d3d3d3; */
      justify-content: space-between;
      padding: 0;
      width: 100%;

      .info-entradas {
        width: 100%;
        display: flex;
        height: 3.5rem;
        div {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #950100;
          /* border-right: 1px solid #d3d3d3; */
          height: 100%;
          width: 25%;

          h3 {
            margin: 0;
            text-align: center;
          }
        }

        .number {
          width: 10%;
        }

        .qtd {
          width: 20%;
        }

        .product-div {
          flex-direction: column;
          width: 45%;

          h2 {
            font-size: 1.2rem;
          }

          h4 {
            color: #333;
            font-size: 0.8rem;
          }
        }

        .create {
          h3 {
            font-size: 1.2rem;
          }
        }
      }

      .info-parcial {
        height: 3rem;
        width: 100%;
        border-bottom: 1px solid #d3d3d3;
        display: flex;

        section {
          margin: 0;
          padding: 0;
        }

        .parciais {
          width: 80%;
          display: flex;
          align-items: start;
          justify-content: flex-start;
          padding: 0 10px;

          div {
            background-color: green;
            border-radius: 3px;
            color: #fff;
            font-size: 1.2rem;
            padding: 5px 10px;
            margin-right: 5px;

            h4 {
              margin: 0;
            }
          }
        }

        .colors {
          width: 20%;
          display: flex;
          justify-content: center;
          align-items: start;

          h4 {
            background-color: #ed3237;
            border-radius: 3px;
            color: rgb(255, 255, 255);
            font-size: 1.2rem;
            padding: 5px 10px;
            margin-right: 5px;
          }

          .ok {
            background-color: #282829;
          }

          img {
            width: 1.8rem;
            margin-right: 15px;
          }
        }
      }
    }
  }

  .selected {
    background-color: #950100;
    .info-parcial {
      /* background-color: #00a859 !important; */
      section {
        div {
          background-color: #fff !important;
          h4 {
            color: #950100 !important;
          }
        }
      }
    }
    div {
      background-color: #950100;

      h2,
      h3,
      h4 {
        color: #fff !important;
      }

      border-right: 0px !important;
    }
  }

  /* Modais */

  .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6); /* Cor de fundo com transparência */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999; /* Z-index alto para sobrepor o conteúdo principal */

    .info1 {
      margin: 0;
    }

    .remove-modal {
      /* height: 80%; */
      width: 55%;
    }

    div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 90%;
      /* height: 80%; */
      background-color: #fff; /* Fundo branco */
      color: #000; /* Cor do texto preta */
      padding: 20px;
      border-radius: 15px;
    }
  }

  .add-edit {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    h1 {
      text-align: center;
      margin: 5px 0;
    }

    form {
      /* height: 100%; */
      width: 100%;
      margin: 20px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;

      label {
        width: 100%;
        margin-bottom: 5px;

        select {
          height: 3rem;
          border-radius: 5px;
          background-color: #fff;
          color: black;
          border: black 2px solid;
          font-weight: bold;
          padding: 0 10px;
          font-size: 1.5rem;
          margin: 10px 0;
          width: 100%;
          box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.3);
        }
      }

      .qtd {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        h3 {
          font-size: 1.5rem;
        }
      }

      input {
        border-radius: 5px;
        padding: 0 10px;
        margin-bottom: 0;
        height: 3rem;
        border: none;
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.3);
        font-size: 1.5rem;
        width: 40%;

        &:focus {
          border: 1px solid #950100;
        }
      }

      input:focus {
        outline: none; /* Remove a borda padrão do navegador */
        border: 3px solid #333;
      }
    }
  }

  .edit {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    /* height: 38rem; */
    h1 {
      text-align: center;
      margin: 10px 0;
    }

    section {
      margin: 0 !important;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      h2 {
        margin-bottom: 5px;
      }
    }

    .section-big {
      margin: 20px 0;
      /* height: 28rem; */
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .info-section {
        /* height: 18rem; */
        margin: 5px 0px !important;

        h2 {
          font-size: 1.5rem;
        }

        .produc {
          height: 2.5rem;
        }
      }

      .info-section > div:last-child {
        margin-bottom: 20px;
      }

      .one {
        /* height: 10rem; */

        overflow-y: scroll;
      }
    }

    form {
      width: 100%;
      display: flex;
      /* height: 8rem; */
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;

      label {
        width: 48%;
        align-items: center;
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        border-radius: 10px;
        font-weight: bold;
        font-size: 1.2rem;
        background-color: #fff;
        border: 3px solid #282829;

        &:hover {
          opacity: 0.9;
        }

        &:active {
          scale: 0.95;
        }

        div {
          width: 1.2rem;
          height: 1.2rem;
          padding: 0;
          margin-right: 5px;
          background-color: #00a859;
          border-radius: 50%; /* Define o raio de 50% para torná-lo um círculo */
        }

        .circle2 {
          background-color: #f58634;
        }

        .circle3 {
          background-color: #ed3237;
        }

        .circle4 {
          background-color: black;
        }
      }

      input {
        border-radius: 5px;
        padding: 0 10px;
        display: none;
        margin-bottom: 0;
        /* height: 3rem; */
        border: none;
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.3);
        font-size: 1.5rem;
        width: 40%;

        &:focus {
          border: 1px solid #950100;
        }
      }

      input:focus {
        outline: none; /* Remove a borda padrão do navegador */
        border: 3px solid #282829;
      }

      /* Estilo para botões de rádio selecionados */
      .selected {
        background-color: #282829;
        color: #fff;

        input {
          display: none;
        }
      }
    }
  }

  .info {
    flex-direction: column;
    margin-top: 0;
    padding: 0;
    h1 {
      margin: 0;
      margin-top: 10px;
    }
  }

  .info2 {
    flex-direction: column;
    margin-top: 30px;
    /* height: 275px; */
    align-items: flex-start;
    justify-content: center;
    /* background-color: #950100; */
    padding: 0;
    h2 {
      text-align: left;
    }
    div {
      display: flex;
      flex-direction: row;
      width: 100%;
    }
  }

  .buttons {
    height: 4rem !important;
    display: flex;
    flex-direction: row !important;
    margin-top: 0;
    padding: 0;
    margin: 0;
    .fechar {
      width: 100%;
    }
    button {
      color: #fff;
      height: 3.5rem;
      border: none;
      border-radius: 5px;
      background-color: #333;
      font-size: 1.5rem;
      font-weight: bold;
      width: 48%;

      &:hover {
        opacity: 0.9;
      }

      &:active {
        scale: 0.95;
      }

      img {
        margin-right: 10px;
        width: 1.6rem;
      }
    }

    .add-button {
      background-color: #00a859;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .edit-button {
      background-color: #f58634;
    }

    .remove-button {
      background-color: #ed3237;
    }
  }

  @media only screen and (max-width: 699px) and (orientation: portrait) {
    .table {
      display: flex;
      flex-direction: column;
      align-items: flex-start; /* Alinha os itens à esquerda */
      background-color: #fff;
      border: 5px solid #950100;
      border-radius: 5px;
      color: #fff;
      width: 95%;
      margin-bottom: 5rem;
    }

    .modal {
      .add-edit {
        /* height: 40%; */
        width: 95%;
      }

      .edit {
        /* height: 82%; */
        width: 95%;
        padding: 15px;
        section {
          /* height: 75%; */
          /* justify-content: flex-start; */

          .info-section {
          }

          .qtd-box-status {
            align-items: center;
            width: 100%;
            padding: 0;
            margin-top: 15px;
            margin-bottom: 5px;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            /* height: 3.5rem; */

            h2 {
              width: 50%;
              margin: 0;
            }

            div {
              width: 60%;
              align-items: center;
              padding: 0;
              flex-direction: row;
              justify-content: flex-end;

              input {
                height: 2.5rem;
                width: 120px;
                font-size: 1.5rem;
                font-weight: bold;
                padding-left: 5px;
                margin-left: 10px;
                border: #282829 2px solid;
                border-radius: 5px;
              }

              input:focus {
                outline: #282829;
                border: #282829 3px solid;
                border-radius: 5px;
              }

              .add-box {
                border-radius: 5px;
                background-color: #00a859;
                width: 2.5rem;
                height: 2.5rem;
                margin-left: 10px;
                align-items: center;
                display: flex;
                justify-content: center;
                img {
                  width: 1.6rem;
                }
              }

              .editar {
                background-color: #f58634;
              }
            }

            .edit {
              background-color: #f58634 !important;
              width: 2.45rem !important;
              height: 2.45rem !important;
              img {
                width: 1.4rem !important;
              }
            }

            .add-box:active {
              scale: 0.95;
            }
          }

          .down {
            margin-top: 5px;
            margin-bottom: 20px;
          }

          .prod {
            margin-top: 0;
            margin-bottom: 20px;
            div {
              padding: 0;
              display: flex;
              flex-direction: row;
              height: 100%;
            }
          }

          .produc {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 0;
            height: 3rem;
            align-items: center;
            width: 100%;
            margin: 4px 0;

            h2 {
              margin: 0;
            }

            .add-box {
              border-radius: 5px;
              background-color: #ed3237;
              width: 2.1rem;
              height: 2.1rem;
              margin-left: 10px;
              align-items: center;
              display: flex;
              justify-content: center;
              img {
                width: 1.6rem;
              }
            }

            .add-box:active {
              scale: 0.95;
            }
          }
        }
      }

      .remove-modal {
        /* height: 70%; */

        width: 95%;
        .info {
          /* height: 80%; */
          h1 {
            margin-bottom: 20px;
          }
          .info2 {
            /* height: 400px; */
            margin: 0;
            justify-content: flex-start;
            div {
              padding: 0;
              height: 2rem;
            }
            .prod-parciais {
              margin: 20px 0;
            }
          }
        }

        h2 {
          margin: 2px 0;
        }
      }
    }
  }

  @media only screen and (min-width: 1200px) {
    .modal {
      .add-edit {
        /* height: 50%; */
        width: 40%;
      }

      .edit {
        /* height: 80%; */
        width: 70%;
        padding: 20px 30px;

        .section-big {
          flex-direction: row;
          /* height: 15rem; */

          .info-section {
            flex-direction: column;
            justify-content: center;
            /* height: 100%; */
            width: 50%;

            div {
              height: 3rem;
              padding: 0;
            }

            .qtd-box-status {
              align-items: center;
              height: 3rem;
              /* background-color: #282829; */
              flex-direction: row;
              padding: 0;
              width: 100%;
              div {
                width: 45%;
                padding: 0;
                flex-direction: row;

                input {
                  align-items: center;
                  border-radius: 5px;
                  font-weight: bold;
                  width: 110px;
                  height: 2.5rem;
                  font-size: 1.5rem;
                  padding: 5px 10px;
                  padding-top: 8px;
                }

                div {
                  cursor: pointer;
                  height: 2.5rem;
                  width: 2.5rem;
                  background-color: #f58634;
                  border-radius: 5px;
                  align-items: center;
                  justify-content: center;

                  img {
                    width: 1.6rem;
                    height: 1.6rem;
                  }
                }
              }
            }

            .down {
              margin: 10px 0;
              div {
                div {
                  background-color: #00a859;
                }
              }
            }

            .produc {
              align-items: center;
              display: flex;
              flex-direction: row;
              width: 100%;
              margin: 5px 0;

              h2 {
                margin: 0;
              }

              .add-box {
                cursor: pointer;
                height: 2.5rem;
                width: 2.5rem;
                background-color: #ed3237;
                border-radius: 5px;
                align-items: center;
                justify-content: center;

                img {
                  width: 1.7rem;
                  height: 1.7rem;
                }
              }
            }
          }

          .one {
            justify-content: center;
            height: 400px;
            overflow-y: hidden;
          }

          #infoo {
            justify-content: center;
            height: 400px;
          }
        }
      }

      .remove-modal {
        /* height: 85%; */
        width: 35%;

        .info {
          height: 80%;
        }

        .info2 {
          .prod-parciais {
            margin: 15px 0;
          }

          div {
            padding: 5px 0;
          }
        }
      }
    }

    #inicial {
      /* width: 50%; */
    }

    .orderControl {
      /* width: 50%; */
    }

    .table {
      /* width: 50%; */
      .list {
        .info-entradas {
          div {
            h3 {
              font-size: 1.5rem;
            }
          }

          .number {
          }

          .qtd {
          }

          .product-div {
            h2 {
            }

            h4 {
            }
          }

          .create {
            h3 {
              font-size: 1.5rem;
            }
          }
        }

        .info-parcial {
          section {
          }

          .parciais {
            div {
              h4 {
              }
            }
          }

          .colors {
            img {
              width: 1.8rem;
              margin-right: 65px;
            }
          }
        }
      }
    }

    .week {
      /* width: 50%; */
      input {
        width: 170px;
      }
    }
  }
`
