import styled from 'styled-components'

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  section {
    align-items: center;
    display: flex;
    height: 3rem;
    justify-content: space-between;
    margin-top: 75px;
    width: 100%;
  }

  .estoque-section {
    width: 95%;
  }

  .tableControl {
    margin: 15px 0;
    width: 95%;

    div {
      border: #ccc solid 1px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      cursor: pointer;
      padding: 2px 3px;

      &:hover {
        opacity: 0.9;
      }

      &:active {
        scale: 0.95;
      }
    }

    #filter {
      width: 2rem;
    }

    button {
      align-items: center;
      display: flex;
      justify-content: center;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      height: 3rem;
      width: 29%;

      &:hover {
        opacity: 0.9;
      }

      &:active {
        scale: 0.95;
      }
    }

    .add-btn {
      background-color: #00a859;
    }

    .edit-btn {
      background-color: #f58634;
    }

    .remove-btn {
      background-color: #ed3237;
    }
  }

  h1 {
    font-size: 1.75;
  }

  .date {
    align-items: center;
    display: flex;
    h1 {
      font-size: 1.5rem;
    }
    img {
      margin-left: 15px;
      height: 2rem;
      width: 2rem;
    }
  }

  article {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border: 5px solid #950100;
    width: 95%;
    border-radius: 10px;

    section {
      align-items: center;
      display: flex;
      background-color: #950100;
      margin: 10px 0;
      width: 100%;
    }

    .list-prop {
      margin: 0;
      height: 3rem;
      width: 100%;
      border: #950100 2px solid;

      div {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: center;

        color: #fff;
        background-color: #950100;
        width: 25%;
        h2 {
          font-size: 1rem;
          text-align: start;
        }
      }

      .pass {
        width: 35%;
      }
    }

    .list-prop div:first-child {
      align-items: flex-start;
      width: 40%;
      h2 {
        text-align: start;
        font-size: 1rem;
      }
      margin-left: 15px;
    }

    .list {
      margin: 0;
      background-color: #fff;
      color: #950100;
      justify-content: space-between;
      border-bottom: 1px solid #d3d3d3;
      height: 3.5rem;
      width: 100%;

      div {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 25%;
        background-color: #fff;
        color: #950100;

        h3 {
          font-size: 1.5rem;
        }
        h4 {
          color: #333;
        }
      }

      div:first-child {
        align-items: flex-start;
        width: 40%;

        margin-left: 15px;
      }

      .pass {
        width: 35%;
      }

      .permission {
        align-items: start;
      }

      /* &:active {
        
      } */
    }

    .selected {
      background-color: #950100;

      div {
        background-color: #950100;

        h2,
        h3,
        h4 {
          color: #fff;
        }
      }
    }
  }

  /* Modais */

  .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6); /* Cor de fundo com transparência */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999; /* Z-index alto para sobrepor o conteúdo principal */

    div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 70%;
      height: 80%;
      background-color: #fff; /* Fundo branco */
      color: #000; /* Cor do texto preta */
      padding: 20px;
      border-radius: 15px;
    }
  }

  .buttons {
    height: 4.4rem;
    margin-top: 0;
    padding: 0;
    button {
      color: #fff;
      height: 3.5rem;
      border: none;
      border-radius: 5px;
      background-color: #333;
      font-size: 1.5rem;
      font-weight: bold;
      width: 48%;

      &:hover {
        opacity: 0.9;
      }

      &:active {
        scale: 0.95;
      }

      img {
        margin-right: 10px;
        width: 1.6rem;
      }
    }

    .add-button {
      background-color: #00a859;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .edit-button {
      background-color: #f58634;
    }

    .remove-button {
      background-color: #ed3237;
    }
  }

  .add-edit {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    h1 {
      margin: 0;
      margin-top: 20px;
    }
    form {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      div {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0;
        height: 5rem;
        margin-bottom: 1rem;
      }

      h2 {
        margin: 0;
      }

      input {
        border-radius: 5px;
        padding: 0 10px;
        margin-bottom: 0;
        height: 3rem;
        border: none;
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.3);
        font-size: 1.5rem;
        width: 100%;

        &:focus {
          border: 1px solid #950100;
        }
      }

      input:focus {
        outline: none; /* Remove a borda padrão do navegador */
        border: 3px solid #333;
      }

      section {
        align-items: center;
        display: flex;
        margin: 0;
        margin-bottom: 20px;
        height: 5rem;
        div {
          padding: 0;
          margin: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          width: 48%;
        }
      }
    }
  }

  .edit-modal {
  }

  .remove-modal {
    height: 50% !important;
    .info {
      align-items: flex-start;
      flex-direction: column;
      width: 100%;
      margin: 0;
      height: 13rem;
      text-align: left;

      div {
        padding: 0;
        justify-content: center;

        h2 {
          color: #950100;
        }
      }

      h2 {
        font-size: 2rem;
      }

      h3 {
        font-size: 1.5rem;
      }
    }

    .buttons {
      height: 3.4rem;
    }
  }

  .legendas {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width: 95%;
    margin-top: 15px;
    padding: 5px;

    h2 {
      margin-bottom: 15px;
      color: #950100;
    }

    h3 {
      margin-bottom: 10px;
    }
  }

  @media only screen and (max-width: 699px) and (orientation: portrait) {
    .modal {
      .add-edit {
        height: 75%;
        width: 90%;
      }

      .edit {
        height: 75%;
        width: 90%;
        padding: 15px;
      }

      .remove-modal {
        height: 30% !important;
        width: 90%;

        h2 {
          margin: 2px 0;
        }
      }
    }
  }

  @media only screen and (min-width: 1200px) {
    /* .estoque-section,
    .tableControl,
    .all-users,
    .legendas {
      width: 60%;
    } */
    .modal {
      .add-edit {
        width: 40%;
      }

      .remove-modal {
        height: 15%;
        width: 25%;
      }
    }
  }
`
