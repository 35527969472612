import React, { useState, useContext, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../../context/userContext'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Container } from './styles'
import Logo2 from '../../assets/logo-secundaria.svg'
import MenuIcon from '../../assets/menu.svg'
import Logo from '../../assets/logo-principal.svg'
import Deslogar from '../../assets/deslogar.svg'
import Perfil from '../../assets/perfil.svg'
import Arrow from '../../assets/seta.svg'

import Estoq from '../../assets/estoque2.svg'

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [logoutOpen, setLogoutOpen] = useState(false)

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const navigate = useNavigate()
  const userContext = useContext(UserContext)

  // Verifica se o contexto de usuário está definido antes de acessar suas propriedades
  if (!userContext) {
    throw new Error('UserContext is undefined')
  }

  const { user, clearUser } = userContext

  // const config = {
  //   headers: {
  //     Authorization: user.token,
  //   },
  // }

  const SignOut = () => {
    clearUser()
    navigate('/')
  }

  const openLogoutModal = () => {
    setLogoutOpen(true)
  }

  const closeLogoutModal = () => {
    setLogoutOpen(false)
  }

  const controleUsuarios = () => {
    if (user.username !== 'admin') {
      toast.info('Você precisa ser o administrador principal')
    } else {
      navigate('/users')
    }
  }

  // funções para detectar toques em toda tela e recolher o menu

  const menuRef = useRef<HTMLDivElement | null>(null)

  const closeMenuOnOutsideClick = (e: MouseEvent) => {
    // Verifica se o clique foi fora do menu
    if (menuRef.current && !(e.target instanceof Node && menuRef.current.contains(e.target))) {
      setIsMenuOpen(false)
    }
  }

  useEffect(() => {
    // Adiciona o event listener quando o componente monta
    document.addEventListener('mousedown', closeMenuOnOutsideClick)

    // Remove o event listener quando o componente desmonta
    return () => {
      document.removeEventListener('mousedown', closeMenuOnOutsideClick)
    }
  }, [])

  return (
    <>
      <Container ref={menuRef}>
        <img src={Logo2} alt='Lourdes Salgados Congelados' />
        <img src={MenuIcon} alt='Abrir menu' onClick={toggleMenu} className='menu' />

        <div className={`menu-container ${isMenuOpen ? 'open' : ''}`}>
          {/* Conteúdo do menu */}
          <h2 onClick={toggleMenu}>x</h2>
          <img src={Logo} alt='Lourdes Salgados Congelados' />
          <h1>Olá, {user.username}</h1>
          <div onClick={openLogoutModal}>
            <img src={Deslogar} alt='Deslogar' />
            <h3>Sair</h3>
          </div>

          <div
            onClick={() => {
              navigate('/estoque')
              setIsMenuOpen(!isMenuOpen)
            }}
          >
            <img src={Estoq} alt='ir para estoque' className='img-est' />
            <h3>Estoque</h3>
          </div>
          <div
            onClick={() => {
              navigate('/entrada')
              setIsMenuOpen(!isMenuOpen)
            }}
          >
            <img src={Arrow} alt='seta para cima' className='arrow arrow-up' />
            <h3>Entradas</h3>
          </div>
          <div
            onClick={() => {
              navigate('/saida')
              setIsMenuOpen(!isMenuOpen)
            }}
          >
            <img src={Arrow} alt='seta para baixo' className='arrow arrow-down' />
            <h3>Saídas</h3>
          </div>

          <div onClick={() => controleUsuarios()}>
            <img src={Perfil} alt='Sessão de Admin' />
            <h3>Usuários</h3>
          </div>
        </div>

        {logoutOpen && (
          <div className='modal'>
            <section>
              <h1>Você deseja sair?</h1>
              <div className='buttons'>
                <button onClick={SignOut} className='sim'>
                  SIM
                </button>
                <button onClick={closeLogoutModal}>NÃO</button>
              </div>
            </section>
          </div>
        )}
      </Container>
      <ToastContainer />
    </>
  )
}
