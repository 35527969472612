import React from 'react'
import { Container } from './styles'
import { Link } from 'react-router-dom'
import Logo from '../../assets/logo-principal.svg'

export default function Footer() {
  return (
    <Container>
      <img src={Logo} alt='logo' />
      <h1>Esta página não existe!</h1>
      <Link to='/'>
        <h1>Voltar para tela inicial</h1>
      </Link>
    </Container>
  )
}
