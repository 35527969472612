import styled from 'styled-components'

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  color: #950100;
  background-color: #fcf2ea;
  justify-content: center;
  height: 100vh;
  width: 100%;
  img {
    margin: 20px;
    width: 20rem;
  }

  a {
    background-color: #950100;
    color: #fcf2ea;
    border-radius: 10px;
    padding: 1px 20px;
    text-decoration: none;
    scale: 0.7;
  }
`
