import React, { createContext, useEffect, useState } from 'react'

export interface User {
  id: number
  username: string
  token: string
  permission: number
}

export const emptyUser: User = {
  username: '',
  id: 0,
  token: 'token',
  permission: 0,
}

export interface UserContextProps {
  user: User
  updateUser: (newUser: User) => void
  clearUser: () => void
}

export interface UserContextProviderProps {
  children: React.ReactNode
}

export const UserContext = createContext<UserContextProps | undefined>(undefined)

const UserProvider = ({ children }: UserContextProviderProps) => {
  const [user, setUser] = useState<User>(emptyUser)

  useEffect(() => {
    // Recupera os valores do localStorage ao inicializar.
    const savedUser = localStorage.getItem('user')
    if (savedUser) {
      try {
        setUser(JSON.parse(savedUser))
      } catch (error) {
        // console.error('Erro ao fazer o parse do usuário do localStorage:', error)
      }
    }
  }, [])

  const updateUser = (newUser: User) => {
    const updatedUser = { ...emptyUser, ...newUser }
    setUser(updatedUser)
    localStorage.setItem('user', JSON.stringify(updatedUser))
  }

  const clearUser = () => {
    setUser(emptyUser)
    localStorage.setItem('user', JSON.stringify(emptyUser))
  }

  const contextValue: UserContextProps = {
    user,
    updateUser,
    clearUser,
  }

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
}

export default UserProvider
